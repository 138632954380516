.Toastify__toast-container {
  z-index: 99999 !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
}

.react-aria-Dialog {
  max-height: inherit;
  box-sizing: border-box;
  outline: none;
  padding: 30px;
  overflow: auto;
}

.react-aria-Dialog .react-aria-Heading[slot=title] {
  margin-top: 0;
  line-height: 1em;
}

.my-overlay {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(10px);

  &[data-entering] {
    animation: mymodal-blur 300ms;
  }

  &[data-exiting] {
    animation: mymodal-blur 300ms reverse ease-in;
  }
}

.my-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  background: #f3f4f6;
  outline: none;

  &[data-entering] {
    animation: mymodal-slide 300ms;
  }

  &[data-exiting] {
    animation: mymodal-slide 300ms reverse ease-in;
  }
}

@keyframes mymodal-blur {
  from {
    background: rgba(45 0 0 / 0);
    backdrop-filter: blur(0);
  }

  to {
    background: rgba(45 0 0 / .3);
    backdrop-filter: blur(10px);
  }
}

@keyframes mymodal-slide {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.grow-animation {
  animation: grow 2s infinite;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1); /* Cor discreta para o polegar da barra de rolagem */
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; /* Torna a pista da barra de rolagem praticamente invisível */
}


.h-ajuste{
  @media (min-width: 1536px) {
    height: 500px;
  }
}

.group:hover .group-hover\:flex {
	display: flex !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar {
  width: 4px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #3498db; /* Cor da barra de rolagem */
  border-radius: 6px; /* Borda arredondada da barra de rolagem */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Cor de fundo da área de rolagem não coberta pela barra */
}

.my-custom-multi-select::part(input-field) {
  border: 2px solid green;
  display: flex;
  /* Coloca o input acima dos itens selecionados */
}

.my-custom-multi-select > input {
  border: 2px solid green;
  order: -2;
  flex-basis: auto;
  margin-right: 20px;
}

.my-custom-multi-select::part(label) {
  color: green;
}

vaadin-multi-select-combo-box::part(input-field) {
  border: 2px solid green;
  display: block;
}

/* Estilo para o botão de alternância (setinha) */
vaadin-multi-select-combo-box::part(toggle-button) {
  order: 0; /* Garante que a setinha fique abaixo do input */
}

/* Estilo para o label */
vaadin-multi-select-combo-box::part(label) {
  color: green;
  margin-top: 50px;
}

/* Estilo para os chips (itens selecionados) */
vaadin-multi-select-combo-box::part(chips) {
  order: -1;
  margin-top: 50px;
  max-width: 570px;
  position: fixed;
  display: flex;
  flex-wrap: wrap; /* Permite que os chips quebrem em múltiplas linhas */
  max-height: calc(2 * 36px); /* Define altura máxima para 2 linhas (ajuste conforme necessário) */
  overflow: hidden; /* Esconde chips adicionais que não cabem nas 2 linhas */
  gap: 8px; /* Espaço entre os chips */
  align-content: flex-end; /* Alinha as linhas no topo do contêiner */
}

.react-aria-Dialog {
  outline: none;
  padding: 30px;
  max-height: inherit;
  box-sizing: border-box;
  overflow: auto;

  .react-aria-Heading[slot=title] {
    line-height: 1em;
    margin-top: 0;
  }
}